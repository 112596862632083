import { onMounted } from "vue";

export function useListener(
  target: Window | HTMLElement,
  event: string,
  callback: (e: Event) => void,
) {
  onMounted(() => {
    target.addEventListener(event, callback);

    return () => {
      target.removeEventListener(event, callback);
    };
  });
}
